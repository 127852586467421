<template>
	<div class="home">
		<!-- header -->
		<homePage :urlFlag="1"></homePage>
		<!-- banner -->
		<div class="container-fluid bannerBox">
			<div class="banner">
        <div class="swiper-container1">
          <div class="swiper-wrapper">
            <div class="swiper-slide" style="z-index: 0; width: 100%; height: 100%; padding: 0;" v-for="(item) in bannerSwiperList" :key="item.id">
				      <img :src="item.img" style="box-sizing: border-box; width: 100%; height:100%; overflow: hidden;">
            </div>
          </div>
        </div>
				<div class="bannerBrief" style="z-index: 11">
					<h2>丝路蓉欧 通达全球</h2>
					<h3>专注国际物流、保税仓运营、跨境贸易供应链一体化服务</h3>
				</div>
			</div>
		</div>
		<!-- 集团业务 -->
		<div class="container group">
			<h2 class="bigTietle"> 
				<span>
					<img src="@/assets/smallLogo.png" alt="" class="smallLogo">
					集团业务
				</span>
			</h2>
			<p class="news">NEWS CENTER</p>
			<div class="container">
				<div class="row groupBox">
					<div class="col-md-4 col-sm-7">
						<div class="aboutUs">ABOUT US</div>
						<p class="groupBrief">四川丝路里国际货运代理有限公司成立于2016年，为第一批蓉欧班列的订舱货运代理。公司的服务主要集中在国际国内集装箱运输、散杂货运输、仓储服务、报关代理等领域；包括订舱、保税仓储、国际跨境中转、集装箱拼装拆箱、报关、报检、海外代理及咨询等业务。</p>
						<ul class="groupList">
							<!-- <li @click="groupFlag = index + 1" :class="{groupOne:groupFlag == index + 1}" v-for="(val,index) of businessList" :key="index">
								<span class="icon font_family icon-icon_srl"></span>
								{{ val.title }}
							</li> -->
							<li @mouseover="groupFlag = 1" :class="{groupOne:groupFlag == 1}">
								<span class="icon font_family icon-icon_srl"></span>
								丝路里——货运代理
							</li>
							<li @mouseover="groupFlag = 2" :class="{groupOne:groupFlag == 2}">
								<span class="icon font_family icon-icon_bdt"></span>
								邦达通——保税仓储
							</li>
							<li @mouseover="groupFlag = 3" :class="{groupOne:groupFlag == 3}">
								<span class="icon font_family icon-icon_tsd"></span>
								铁速达——进出口报关
							</li>
							<li @mouseover="groupFlag = 4" :class="{groupOne:groupFlag == 4}">
								<span class="icon font_family icon-icon_ocj"></span>
								欧城记——跨境贸易
							</li>
						</ul>
						<!-- 手机端展示样式 -->
						<div class="modelGroupList">
							<div class="oneGroup" :style="{'max-height':groupFlag == index + 1 ? '600px' : '68px'}" @click="groupFlag = index + 1" v-for="(val,index) of businessList" :key="index">
								<div class="groupTitle">
									<span class="icon font_family icon-icon_srl"></span>
									{{ val.title }}
									<span class="icon font_family icon-icon_enter" :class="{rotateSpan:groupFlag == 1}"></span>
								</div>
								<div class="groupDetail">
									<img :src="val.img">
									<div>
										<h2>{{ val.title }}</h2>
										<p>{{ val.explain }}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-8 col-sm-5 boxImg" v-show="groupFlag == index + 1" v-for="(val,index) of businessList" :key="index">
							<img :src="val.img">
							<div>
								<div>{{ val.title }}</div>
								<p>{{ val.explain }}</p>
							</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 服务优势 -->
		<div class="container-fluid serviceGood">
			<div class="container">
				<div class="row">
					<div class="col-md-6">
							<h2 class="bigTietle bigTietle2">
								<span><img src="@/assets/fwys-lwwwe.png" alt="" style="position: absolute; left: -9rem; top: 0px; height: 40px;">
								服务优势</span>
							</h2>
							<div style="margin-right: 6em;"><p class="news news2">SERVICE ADVANTAGE</p></div>
						<p class="major">专业供应链服务团队，自建保税仓储，我们是众多知名国际品牌亲密的合作伙伴， 我们不仅是物流合作方， 而且是品牌的战略合作方，大数据的提供方，依托成都、重庆、西安中欧班列、航运枢纽等功能，充分发挥企业自身一手资源、保税运营、平台战略合作、实体门店等优势，力求把企业打造成跨境供应链行业标杆型企业。</p>
					</div>
					<div class="col-md-6 serviceBox">
						<div>
							<div class="icon font_family icon-icon_service_31"></div>
							<p>专业供应链服务</p>
						</div>
						<div>
							<div class="icon font_family icon-icon_service_21"></div>
							<p>专业关务团队</p>
						</div>
						<div>
							<div class="icon font_family icon-icon_service_11"></div>
							<p>自建保税仓储</p>
						</div>
						<div>
							<div class="icon font_family icon-icon_service_41"></div>
							<p>跨境贸易链条</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 合作平台 -->
		<div class="container cooperation">
			<h2 class="bigTietle"> 
				<span>
					<img src="@/assets/smallLogo.png" alt="" class="smallLogo">
					合作平台
				</span>
			</h2>
			<p class="news">COOPERATIVE PARTNER</p>
			<p class="cooperationDetail">我司众多品牌保持着长期稳定的合作伙伴关系，也在探讨行业趋势并进一步深化合作</p>
			<div class="row" style="margin-top: 2em">
				<div class="col-xs-6 col-md-2 col-sm-4 workBox workBoxName">
					跨境合作品牌
					<span class="icon font_family icon-shuangjiantouyou"></span>
				</div>
				<div class="col-xs-6 col-md-2 col-sm-4 workBox">
					<div><img src="@/assets/img_brand1.png"></div>
				</div>
				<div class="col-xs-6 col-md-2 col-sm-4 workBox">
					<div><img src="@/assets/img_brand2.png"></div>
				</div>
				<div class="col-xs-6 col-md-2 col-sm-4 workBox">
					<div><img src="@/assets/img_brand3.png"></div>
				</div>
				<div class="col-xs-6 col-md-2 col-sm-4 workBox">
					<div><img src="@/assets/img_brand4.png"></div>
				</div>
				<div class="col-xs-6 col-md-2 col-sm-4 workBox">
					<div><img src="@/assets/img_brand5.png"></div>
				</div>
			</div>
			<div class="row">
				<div class="col-xs-6 col-md-2 col-sm-4 workBox workBox2">
					<div><img src="@/assets/img_home_warehouse_1.png"><p>波兰华沙仓库</p></div>
				</div>
				<div class="col-xs-6 col-md-2 col-sm-4 workBox workBox2">
					<div><img src="@/assets/img_home_warehouse_2.png"><p>荷兰鹿特丹仓库</p></div>
				</div>
				<div class="col-xs-6 col-md-2 col-sm-4 workBox workBox2">
					<div><img src="@/assets/img_home_warehouse_3.png"><p>英国仓库</p></div>
				</div>
				<div class="col-xs-6 col-md-2 col-sm-4 workBox workBox2">
					<div><img src="@/assets/img_home_warehouse_4.png"><p>捷克布拉德仓库</p></div>
				</div>
				<div class="col-xs-6 col-md-2 col-sm-4 workBox workBox2">
					<div><img src="@/assets/img_home_warehouse_5.png"><p>芬兰赫尔辛基仓库</p></div>
				</div>
				<div class="col-xs-6 col-md-2 col-sm-4 workBox workBoxName">
					<span class="icon font_family icon-shuangjiantouzuo"></span>
					海外仓储
				</div>
			</div>
		</div>
		<!-- 成功案例 -->
		<div class="container-fluid">
			<h2 class="bigTietle"> 
				<span>
					<img src="@/assets/smallLogo.png" alt="" class="smallLogo">
					成功案例
				</span>
			</h2>
			<p class="news">OUR PROJECT</p>
			<!-- 轮播 -->
			<div class="container">
				<div class="swiper-container">
					<div class="swiper-wrapper">
						<div class="swiper-slide" v-for="(val,index) of swiperList" :key="index">
							<div>
								<div class="swiperBoxImg">
									<img :src="val.img">
								</div>
								<div class="swiperSmallBox">
									<div>{{ val.title }}</div>
									<p>{{ val.explain }}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="icon font_family icon-icon_enter" @click="swiperNext()"></div>
					<div class="icon font_family icon-icon_back" @click="swiperPrev()" ></div>
				</div>
				<div style="height: 2px" v-if="false"></div>
				<div class="progress">
					<div :style="{width: swiperWidth + '%'}"></div>
				</div>
			</div>
		</div>
		<!-- 联系我们 -->
		<div class="container-fluid contactUs">
			<img src="@/assets/bg_contact.png" class="contactImg">
			<h2 class="bigTietle bigTietleUs">联系我们</h2>
			<p class="news">CONTACT US</p>
			<div class="container">
				<div class="row">
					<div class="col-sm-4 inforBox">
						<div class="icon font_family icon-icon-mail"></div>
						<p>srl-market@srleader.com</p>
						<p>sales@railtec-log.com</p>
					</div>
					<div class="col-sm-4 inforBox">
						<div class="icon font_family icon-icon_phone_white"></div>
						<p>Fisher YU 18602865214</p>
						<p>Maggie 17360190854</p>
					</div>
					<div class="col-sm-4 inforBox">
						<div class="icon font_family icon-icon_location_white"></div>
						<p>成都市高新区交子大道177号中海</p>
						<p>国际中心A座1506-1508</p>
					</div>
				</div>
			</div>
		</div>
		<afterbody></afterbody>
	</div>
</template>

<script>
// @ is an alias to /src
import homePage from "@/components/homePage/homePage.vue";
import afterbody from "@/components/afterbody/afterbody.vue";
import Swiper from 'swiper'

export default {
	name: "Home",
	components: {
		homePage,
		afterbody
	},
	data() {
		return {
      bannerSwiperList: [{
        img: require('@/assets/img_home_banner1.png'),
        id: 1
      },{
        img: require('@/assets/img_home_banner3.png'),
        id: 3
      },{
        img: require('@/assets/img_home_banner2.png'),
        id: 2
      }],
			groupFlag: 1,
			swipers: {},
			swiperWidth: 0, // 轮播显示的百分比
			swiperShow: 3, // 轮播每页显示的条数
			// 轮播列表
			swiperList: [
				{
					img: require('@/assets/img_home_case_1.png'),
					title: '中欧班列订舱',
					explain: '月均拼箱最高可达35＋条整柜，成都唯一一家可操作多种出口模式。'
				}, {
					img: require('@/assets/img_home_case_2.png'),
					title: '跨境包裹',
					explain: '跨境电商多样化选择1039/9610/9710/9810，散货、整柜均可。'
				}, {
					img: require('@/assets/img_home_case_3.png'),
					title: '海外仓',
					explain: '荷兰自有海外仓，多国合作海外仓，多一份放心。'
				}, {
					img: require('@/assets/img_home_case_4.png'),
					title: '国内保税仓',
					explain: '成都、重庆、西安，多地自有保税仓，区内企业，更多选择。'
				}, {
					img: require('@/assets/img_home_case_5.png'),
					title: '跨境保税仓',
					explain: '跨境电商企业直采，保税仓库配送至客户，更快捷。'
				}, {
					img: require('@/assets/img_home_case_6.png'),
					title: '进出口货代',
					explain: '进出口业务均涵盖站到站、门到站、站到门、门到门所有模式'
				}, {
					img: require('@/assets/img_home_case_7.png'),
					title: '9710/9810报关单、清单模式',
					explain: '跨境电商出口货物，代理通关，办理通关手续更专业'
				}, {
					img: require('@/assets/img_home_case_8.png'),
					title: '紧急物资通关',
					explain: '2020年2月疫情防控期间100万只捐赠口罩到货，为一线人员雪中送炭'
				}
			],
			businessList: [
				{
					img: require('@/assets/img_home_aboutus_1.png'),
					title: '丝路里——货运代理',
					explain: '“四川丝路里物流科技有限公司”是第一批蓉欧班列的订舱货运代理，我们立志成为综合运输和物流服务的领军者之一。'
				}, {
					img: require('@/assets/img_home_aboutus_2.png'),
					title: '邦达通——保税仓储',
					explain: '“四川邦达通供应链管理有限公司”秉承以客为尊、精益求精及不断创新的服务精神。着力打造以西南地区为基地并面向全球的仓储服务，为各类型客户提供最专业，最完善的供应链服务。'
				}, {
					img: require('@/assets/img_home_aboutus_3.png'),
					title: '铁速达——进出口报关',
					explain: '“四川铁速达物流有限公司”是中华人民共和国海关总署正式批准的专业报关企业，可在中国境内各主要口岸提供优质服务的专业报关行。坚持以客户为中心，快速响应客户需求，持续为客户创造长期价值进而成就客户。'
				}, {
					img: require('@/assets/img_home_aboutus_4.png'),
					title: '欧城记——跨境贸易',
					explain: '“四川欧城记跨境电子商务有限公司”为海外供应商提供，更多国内销售渠道。为国内小Ｂ客户提供更多，优质货源及保税代发服务。同时亦是把信息科技及电子商贸应用在全方位货物运输过程。'
				}
			],
			iconList: [
				'icon-icon_srl',
				'icon-icon_bdt',
				'icon-icon_tsd',
				'icon-icon_ocj'
			]
		}
	},
	computed: {
	},
	methods: {
    swiper1() {
      new Swiper('.swiper-container1',{
        autoplay: {
					disableOnInteraction: false,  //触碰后自动轮播也不会停止
					delay: 5000,
				},
      })
    },
		// swiper() {
		// 	var that = this
		// 	this.swipers = new Swiper ('.swiper-container', {
		// 		slidesPerView: that.swiperShow,
		// 		spaceBetween: 3,
		// 		slidesPerGroup: that.swiperShow,
		// 		loop: true,
		// 		autoplay: 3000,
		// 		loopFillGroupWithBlank: true,
		// 		grabCursor : true,
		// 		setWrapperSize :true,
		// 		paginationClickable :true,
		// 		observeParents:true, //修改swiper的父元素时，自动初始化swiper
		// 		observer: true,//修改swiper自己或子元素时，自动初始化swiper
		// 		on: {
		// 			slideChangeTransitionStart() {
		// 				if (this.activeIndex > that.swiperList.length) {
		// 					that.swiperWidth = (this.activeIndex - that.swiperList.length) / that.swiperList.length * 100
		// 				} else {
		// 					that.swiperWidth = this.activeIndex / that.swiperList.length * 100
		// 				}
		// 			},
		// 		},
		// 	})
		// },
		swiper() {
			var that = this
			this.swipers = new Swiper ('.swiper-container', {
				slidesPerView: that.swiperShow,
				spaceBetween: 3,
				slidesPerGroup: that.swiperShow,
				loop: true, //循环
				observer: true,//修改swiper自己或子元素时，自动初始化swiper
				observeParents: true,//修改swiper的父元素时，自动初始化swiper
				autoplay: {
					disableOnInteraction: false,  //触碰后自动轮播也不会停止
					delay: 5000,
				},
				on: {
					slideChangeTransitionStart() {
						if (this.activeIndex > that.swiperList.length) {
							that.swiperWidth = (this.activeIndex - that.swiperList.length) / that.swiperList.length * 100
						} else {
							that.swiperWidth = this.activeIndex / that.swiperList.length * 100
						}
					},
				},
			})
		},

		// 轮播下一页
		swiperNext() {
			this.swipers.slideNext()
		},
		// 轮播上一页
		swiperPrev() {
			this.swipers.slidePrev()
		},
		// 控制轮播每页显示数量
		handleScroll() {
			var Flag = -1
			if (document.body.offsetWidth > 992) {
				Flag = 4
			} else if (document.body.offsetWidth > 767) {
				Flag = 2
			} else {
				Flag = 1
			}
			if (Flag != this.swiperShow) {
				this.swiperShow = Flag
				this.$nextTick(() => {
					this.swiper()
				})
			}
		}
	},
	mounted() {
    this.$nextTick(() => {
			this.swiper1();
		})
		this.handleScroll()
		window.addEventListener("scroll",this.handleScroll)
		window.onresize = () => {
			this.handleScroll()
		}
	},
	unmounted() {
      	document.removeEventListener('scroll', this.handleScroll)
    }
};
</script>

<style scoped>
@import "index.css";
</style>
